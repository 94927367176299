import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Container, Stack, Typography, Button, Card, FormLabel, Input, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import DatePicker from 'react-date-picker';
import Swal from 'sweetalert2'; // Import SweetAlert
import PageLoader from '../components/loader';
import Iconify from '../components/iconify';
import { formatTimeSlot, TimeSlots } from '../utils/helperfunction';
import api from '../constants/api';

const useStyles = makeStyles(() => ({
  modal_controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '15px',
  },

  formLabelStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'grey',
    marginTop: '10px',
  },
  input: {
    width: '100%',
    margin: '10px 0',
    // border: '1px solid',
    // borderRadius: '10px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    backgroundColor: '#FAFAFB',
    padding: '5px 10px',
    // background-color: '#FAFAF',
    // padding: '5px 10px'
  },
}));

const QuotePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [date, setdate] = useState(location?.state?.selected?.dateSlot);
  const [isEditButton, setisEditButton] = useState(false);
  const isDisabled = location?.state?.isDisabled || false;
  const [loading, setLoading] = useState(false);
  const [quotationDetails, setquotationDetails] = useState({
    name: '',
    bulb: '',
    fan: '',
    tv: '',
    led: '',
    ac: '',
    time: '',
    switch: '',
    description: '',
    approxAmount: '',
    calculatedAmount: '',
  });

  const handleOnChange = (e) => {
    setquotationDetails({ ...quotationDetails, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!location?.state) {
      navigate('/');
    }
    const { dateSlot, timeSlot } = location?.state?.selected;
    setdate(dateSlot);
    fetchQuotation();
  }, []);

  
  const fetchQuotation = async () => {
    try{
      setLoading(true);
      const bookingId = location?.state?.selected?._id;
      const response = await api.getBookingQuotation(bookingId);
      setLoading(false);
      const {timeSlot} = location?.state?.selected;
      if(response?.data){
        setquotationDetails({
          ...quotationDetails,
          name: response?.data?.name,
          bulb: response?.data?.bulb,
          fan: response?.data?.fan,
          tv: response?.data?.tv,
          led: response?.data?.led,
          ac: response?.data?.ac,
          switch: response?.data?.switch,
          description: response?.data?.description,
          approxAmount: response?.data?.approxAmount,
          calculatedAmount: response?.data?.calculatedAmount,
          time: formatTimeSlot({ time: getTimeFromSlots(timeSlot) }),
          paid: response?.data?.paid
        })
        if(!location?.state?.isDisabled){
          setisEditButton(true);
        }
      }else{
        setquotationDetails({
          ...quotationDetails,
          time: formatTimeSlot({ time: getTimeFromSlots(timeSlot) })
        })      
      }
    }catch(error){
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Action Failed',
        text: error?.message || error.toString(),
      });
    }
  }

  const getTimeFromSlots = (timeSlot) => {
    const item = TimeSlots?.find((item) => item?.slot === Number(timeSlot));
    return item?.time || 'N/A';
  };

  const addQuote = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const params = {
        booking: location?.state?.selected?._id,
        name: quotationDetails.name,
        bulb: quotationDetails.bulb,
        fan: quotationDetails.fan,
        tv: quotationDetails.tv,
        led: quotationDetails.led,
        ac: quotationDetails.ac,
        switch: quotationDetails.switch,
        description: quotationDetails.description,
        approxAmount: quotationDetails.approxAmount,
        calculatedAmount: quotationDetails.calculatedAmount,
      };
      const response = await api.createQuotation(params);
      const params1 = {
        _id: location?.state?.selected?._id,
        bookingStatus: "654c17df4a6ba736343d8dc9"
      }
      const response1 = await api.updateBooking(params1);
      navigate("/booking", {replace: true})
      toast.success("Quote Registered Successfully...")
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Action Failed',
        text: error?.message || error.toString(),
      });
    }
  };

  const updateQuote = async (e) => {
    e.preventDefault();
    try{
      setLoading(true);
      const params = {
        _id: location?.state?.selected?._id,
        ...quotationDetails
      }
      const response = await api.updateQuotation(params);
      toast.success("Quote Updated Successfully...")
      navigate("/booking", {replace: true})
      setLoading(false);
    }catch(error){
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Action Failed',
        text: error?.message || error.toString(),
      });
    }
  }

  return (
    <>
      {loading && <PageLoader />}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create Quotation
          </Typography>

          <Button className="bg-[#605BFF] hover:bg-[#5651d7]" onClick={() => navigate('/booking')} variant="contained">
            Back
          </Button>
        </Stack>

        <Card sx={{ padding: 5 }}>
          <div className={classes.modal_controls}>
            <form className='w-full' onSubmit={isEditButton ? updateQuote : addQuote}>
              <div className="w-full grid max-[400px]:grid-cols-1 grid-cols-2 sm:grid-cols-3 gap-x-5 md:gap-x-10">
                <div>
                  <FormLabel required className={classes.formLabelStyle} component="legend">
                    Quote Name
                  </FormLabel>
                  <Input
                    value={quotationDetails.name}
                    onChange={handleOnChange}
                    required
                    name="name"
                    placeholder=""
                    disabled={isDisabled || quotationDetails?.paid}
                    className={classes.input}
                  />
                </div>
                <div>
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    Date
                  </FormLabel>
                  <DatePicker
                    disabled
                    className={[
                      classes.input,
                      '!py-[7.5px] bg-black border-b-[1px] border-b-[#ccc] border-solid hover:border-b-[#212B36] cursor-pointer',
                    ]}
                    // className={'w-full my-[10px] border-b-[2px] border-b-[#ccc] border-solid hover:border-b-[#212B36] cursor-pointer'}
                    dayPlaceholder="DD"
                    yearPlaceholder="YY"
                    monthPlaceholder="MM"
                    calendarClassName={'calenderClass'}
                    calendarIcon={<Iconify icon={'uil:calender'} color={'#605BFF'} />}
                    clearIcon=""
                    onChange={setdate}
                    value={date}
                  />
                </div>
                <div>
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    Time
                  </FormLabel>
                  <Input
                    value={quotationDetails.time}
                    onChange={handleOnChange}
                    disabled
                    name="time"
                    placeholder=""
                    className={classes.input}
                  />
                </div>
                <div>
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    Bulb
                  </FormLabel>
                  <Input
                    value={quotationDetails.bulb}
                    onChange={handleOnChange}
                    name="bulb"
                    placeholder=""
                    className={classes.input}
                    disabled={isDisabled || quotationDetails?.paid}
                  />
                </div>

                <div>
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    Fan
                  </FormLabel>
                  <Input
                    value={quotationDetails.fan}
                    onChange={handleOnChange}
                    name="fan"
                    placeholder=""
                    className={classes.input}
                    disabled={isDisabled || quotationDetails?.paid}
                  />
                </div>

                <div>
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    TV
                  </FormLabel>
                  <Input
                    value={quotationDetails.tv}
                    onChange={handleOnChange}
                    name="tv"
                    placeholder=""
                    className={classes.input}
                    disabled={isDisabled || quotationDetails?.paid}
                  />
                </div>

                <div>
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    LED
                  </FormLabel>
                  <Input
                    value={quotationDetails.led}
                    onChange={handleOnChange}
                    name="led"
                    placeholder=""
                    className={classes.input}
                    disabled={isDisabled || quotationDetails?.paid}
                  />
                </div>

                <div>
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    AC
                  </FormLabel>
                  <Input
                    value={quotationDetails.ac}
                    onChange={handleOnChange}
                    name="ac"
                    placeholder=""
                    className={classes.input}
                    disabled={isDisabled || quotationDetails?.paid}
                  />
                </div>

                <div>
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    Switch
                  </FormLabel>
                  <Input
                    value={quotationDetails.switch}
                    onChange={handleOnChange}
                    name="switch"
                    placeholder=""
                    className={classes.input}
                    disabled={isDisabled || quotationDetails?.paid}
                  />
                </div>

                <div className="col-span-full">
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    Description
                  </FormLabel>
                  <TextField
                    value={quotationDetails.description}
                    onChange={handleOnChange}
                    name="description"
                    id="standard-multiline-static"
                    multiline
                    rows={3}
                    defaultValue={''}
                    variant="standard"
                    disabled={isDisabled || quotationDetails?.paid}
                    className={classes.input}
                  />
                </div>

                <div>
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    Approx Amount
                  </FormLabel>
                  <Input
                    type="number"
                    value={quotationDetails.approxAmount}
                    onChange={handleOnChange}
                    name="approxAmount"
                    placeholder=""
                    disabled={isDisabled || quotationDetails?.paid}
                    className={classes.input}
                  />
                </div>

                <div>
                  <FormLabel className={classes.formLabelStyle} component="legend">
                    Calculated Amount
                  </FormLabel>
                  <Input
                    type="number"
                    value={quotationDetails.calculatedAmount}
                    onChange={handleOnChange}
                    name="calculatedAmount"
                    placeholder=""
                    disabled={isDisabled || quotationDetails?.paid}
                    className={classes.input}
                  />
                </div>
                
                {!quotationDetails?.paid && (
                <div className="flex justify-end items-end translate-y-[-10px]">
                  {!isDisabled && <Button className="bg-[#605BFF] hover:bg-[#5651d7]" variant="contained" type="submit">
                    {isEditButton ? "Update" : "Save"}
                  </Button>}
                </div>
                )}
              </div>
            </form>
          </div>
        </Card>
      </Container>
    </>
  );
};

export default QuotePage;
