import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2'; // Import SweetAlert
import api from '../constants/api';
import { setUser } from '../redux/slices/user/user';
import TextField from '../components/TextField';

// @mui
// import { styled } from '@mui/material/styles';
// import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
// import useResponsive from '../hooks/useResponsive';
// components
// import Logo from '../components/logo';
// import Iconify from '../components/iconify';
// sections
// import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

// const StyledRoot = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex',
//   },
// }));

// const StyledSection = styled('div')(({ theme }) => ({
//   width: '100%',
//   maxWidth: 480,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   boxShadow: theme.customShadows.card,
//   backgroundColor: theme.palette.background.default,
// }));

// const StyledContent = styled('div')(({ theme }) => ({
//   maxWidth: 480,
//   margin: 'auto',
//   minHeight: '100vh',
//   display: 'flex',
//   justifyContent: 'center',
//   flexDirection: 'column',
//   padding: theme.spacing(12, 0),
// }));

// ----------------------------------------------------------------------

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const mdUp = useResponsive('up', 'md');
  const [loading, setLoading] = useState(false); // State to manage loading
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
  });

  const handleChange = (evt) => {
    const value = evt.target.value;
    setUserDetails({
      ...userDetails,
      [evt.target.name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onLogin = async (e) => {
    e.preventDefault();
    try{
      setLoading(true);
      const response = await api.login(userDetails);
      if(response?.data?.updatedUser?.isFirstLogin){
        navigate("/auth/verify", {state: {userDetails, data: response?.data}})
      }else{
        dispatch(setUser(response?.data));
      }
      setLoading(false);
    }catch(error){
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Action Failed',
        text: error?.message || error?.toString(),
      });
    }
  };

  return (
    <div className="w-[90%] md:w-[80%]">
      <h1 className="text-[#605BFF] font-black text-3xl text-center">Supereme Facilities</h1>
      <h3 className="font-bold text-2xl text-center">Sign In</h3>

      {/* <div className='my-5'>
        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Email Address
          <input
            type="text"
            id="email"
            name="email"
            className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </label>
      </div> */}
      <form onSubmit={onLogin}>
        <TextField
          type="email"
          label={'Email Address'}
          name="email"
          background="light"
          value={userDetails.email}
          onChange={handleChange}
        />

        <div className="my-5">
          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-dark  ">
            Password
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={userDetails.password}
                onChange={handleChange}
                className="mt-1 bg-gray-50 border bg-transparent focus:outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
              <button
                type="button"
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>
          </label>
        </div>

        {/* <button
          type="submit"
          className="w-full focus:outline-none text-white bg-[#605BFF] hover:bg-[#5651d7] font-medium rounded-lg text-sm px-5 py-3 my-5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
        >
          Login
        </button> */}
        <LoadingButton
        fullWidth
        size="large"
        type="submit"
        className='w-full focus:outline-none text-white bg-[#605BFF] hover:bg-[#5651d7] font-medium rounded-lg text-sm px-5 py-3 my-5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900'
        variant="contained"
        loading={loading} // Use the loading state to manage the loading state of the button
      >
        Login
      </LoadingButton>
      </form>
    </div>
  );
};

export default LoginPage;
