import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const user = useSelector((state) => state?.userReducer?.user?.updatedUser);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => {
          if (item?.path === '/user' && user?.role?.code !== 'admin') {
            return null;
          }
          if (item?.path === '/product' && user?.role?.code !== 'admin') {
            return null;
          }
          if (item?.path === '/category' && user?.role?.code !== 'admin') {
            return null;
          }

          return <NavItem key={item.title} item={item} />;
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: '#605BFF',
          bgcolor: 'rgba(189,187,255, 0.3)',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
