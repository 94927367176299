import React from 'react'

const TextField = (props) => {
  return (
    <div className='my-5'>
        <label htmlFor={props.name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-dark">
          {props.label}
          <input
            type={props.type}
            id={props.name}
            name={props.name}
            value={props.value}
            disabled={props.disabled || false}
            onChange={props.onChange}
            className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </label>
      </div>
  )
}

export default TextField