import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import UserPage from './pages/UserPage';
import RegisterPage from './pages/RegisterPage';
import VerificationPage from './pages/VerificationPage';
import BookingPage from './pages/BookingPage';
import QuotePage from './pages/QuotePage';
import UpdateProfile from './pages/UpdateProfile';
import DashboardAppPage from './pages/DashboardAppPage'
import CategoryPage from './pages/Category';
import ProductCPage from './pages/Product';
import Spreadsheets from './pages/Spreadsheets';
import SpreadsheetsRecords from './pages/SpreadSheetRecord';
// ----------------------------------------------------------------------

export default function Router() {
  const user = useSelector((state) => state?.userReducer?.user?.updatedUser);

  const {token, updatedUser} = useSelector((state) => state?.userReducer?.user || {});
  // Apply logic: if token is present, redirect unauthorized users to /dashboard
  const protectedRoutes = (children) => (token ? children : <Navigate to="/auth/login" />);
  const routes = useRoutes([
    {
      path: '/',
      element: token ? <DashboardLayout /> : <SimpleLayout />,
      children: [
        { element: protectedRoutes(<Navigate to="/spreadsheet" />), index: true },
        { path: 'dashboard', element: updatedUser?.role?.code === "admin" ? protectedRoutes(<SpreadsheetsRecords />) : <Navigate to="/" />},
        { path: 'user', element: updatedUser?.role?.code === "admin" ? protectedRoutes(<UserPage />) :  <Navigate to="/" />},
        { path: 'category', element: updatedUser?.role?.code === "admin" ? protectedRoutes(<CategoryPage />) :  <Navigate to="/" />},
        { path: 'product', element: updatedUser?.role?.code === "admin" ? protectedRoutes(<ProductCPage />) :  <Navigate to="/" />},
        { path: 'setting/updateProfile', element: protectedRoutes(<UpdateProfile />) },
        { path: 'spreadsheet', element: protectedRoutes(<Spreadsheets />) },
        { path: 'spreadsheets/records', element: protectedRoutes(<SpreadsheetsRecords />) },
      ],
    },
    {
      path: '/auth',
      
      element: token ? <Navigate to="/user" /> : <SimpleLayout />, // Render LoginPage only if no token
      children: [
        { element: token ? null : <Navigate to="/auth/login" />, index: true }, // Redirect to login if no token
        { path: 'login', element: token ? null : <LoginPage /> }, // Show login page only if no token
        { path: 'register', element: token ? null : <RegisterPage /> }, // Show Register page only if no token
        { path: 'verify', element: <VerificationPage />}
      ],
    },

    // 404 page for routes that don't exist
    {
      path: '*',
      element: <Page404 />,
    },
  ]);

  return routes;
}
