import constants from './constants';
import axios from '../utils/axios/index';

const login = async (body) => {
  const url = `${constants.BASE_URL}/user/login`;
  return axios.post(url, body);
};

const verifyOTP = async (body) => {
  const url = `${constants.BASE_URL}/user/verify/auth/two-factor`;
  return axios.post(url, body);
};

const fetchBooking = async (queryString, body) => {
  const url = `${constants.BASE_URL}/booking/slot/get${queryString}`;
  return axios.get(url, body);
};

const createBooking = async (body) => {
  const url = `${constants.BASE_URL}/booking/slot/create`;
  return axios.post(url, body);
};

const updateBooking = async (body) => {
  const url = `${constants.BASE_URL}/booking/slot/update`;
  return axios.patch(url, body);
};

const fetchAvailableSlots = async (body) => {
  const url = `${constants.BASE_URL}/booking/slot/get/all/available`;
  return axios.post(url, body);
};

const getBookingQuotation = async (bookingId, body) => {
  const url = `${constants.BASE_URL}/quotation/get/all?booking=${bookingId}`;
  return axios.get(url, body);
};

const createQuotation = async (body) => {
  const url = `${constants.BASE_URL}/quotation/create`;
  return axios.post(url, body);
};

const updateQuotation = async (body) => {
  const url = `${constants.BASE_URL}/quotation/update/by-booking-id`;
  return axios.patch(url, body);
};

const fetchUsers = async (body) => {
  const url = `${constants.BASE_URL}/user/get/all`;
  return axios.get(url, body);
};

const updateUser = async (body) => {
  const url = `${constants.BASE_URL}/user/update`;
  return axios.patch(url, body);
};

const requestNewPassword = async (body) => {
  const url = `${constants.BASE_URL}/user/request-change-password`;
  return axios.patch(url, body);
};

const fetchRoles = async (body) => {
  const url = `${constants.BASE_URL}/role/get/all`;
  return axios.get(url, body);
};

const registerUser = async (body) => {
  const url = `${constants.BASE_URL}/user/register`;
  return axios.post(url, body);
};

const fetchProducts = async (body) => {
  const url = `${constants.BASE_URL}/product/get/all`;

  return axios.get(url, body);
};

const fetchCategory = async (body) => {
  const url = `${constants.BASE_URL}/category/get/all`;

  return axios.get(url, body);

};

const createCategory = async (body) => {
  const url = `${constants.BASE_URL}/category/create`;

  return axios.post(url, body);

};

const updateCategory = async (body) => {
  const url = `${constants.BASE_URL}/category/update`;

  return axios.patch(url, body);

};



const createProduuct = async (body) => {
  const url = `${constants.BASE_URL}/product/create`;

  return axios.post(url, body);
};

const updateProduct = async (body) => {
  const url = `${constants.BASE_URL}/product/update`;

  return axios.patch(url, body);
};

const deleteProduct = async (body) => {
  const url = `${constants.BASE_URL}/product/delete`;

  return axios.patch(url, body);
};

const addSpreadsheet = async (body) => {
  const url = `${constants.BASE_URL}/spreadsheet/create`;

  return axios.post(url, body);
};

const updateSpreadsheet = async (body) => {
  const url = `${constants.BASE_URL}/spreadsheet/update`;

  return axios.patch(url, body);
}

const fetchSpreadsheet = async (body) => {
  const url = `${constants.BASE_URL}/spreadsheet/get/all`;

  return axios.get(url, body);
}

const fetchSpreadsheetRecords = async (body) => {
  const url = `${constants.BASE_URL}/sheetrecord/get/by-spreadsheet-id`;

  return axios.get(url, body);
}

const mailSpreadsheetRecords = async (body) => {
  const url = `${constants.BASE_URL}/sheetrecord/email/by-spreadsheet-id`;

  return axios.get(url, body);
}

const createSpreadsheetRecords = async (body) => {
  const url = `${constants.BASE_URL}/sheetrecord/create`;

  return axios.post(url, body);
}

const updateSpreadsheetRecords = async (body) => {
  const url = `${constants.BASE_URL}/sheetrecord/update`;

  return axios.patch(url, body);
}

export default {
  login,
  fetchUsers,
  fetchProducts,
  updateUser,
  requestNewPassword,
  fetchRoles,
  registerUser,
  createProduuct,
  updateProduct,
  deleteProduct,
  verifyOTP,

  mailSpreadsheetRecords,
  fetchCategory,
  fetchBooking,
  createBooking,
  updateBooking,
  fetchAvailableSlots,
  getBookingQuotation,
  createQuotation,
  updateQuotation,
  createCategory,
  updateCategory,
  addSpreadsheet,
  updateSpreadsheet,
  fetchSpreadsheet,
  fetchSpreadsheetRecords,
  createSpreadsheetRecords,
  updateSpreadsheetRecords
};
