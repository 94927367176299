import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import { FormLabel, MenuItem } from '@mui/material';
import { AvailableLocations } from '../../utils/helperfunction';

import styles from './styles.css';
import Iconify from '../iconify';

const useStyles = makeStyles(() => ({
  gloabalModal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.2)',
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'hidden',
    backdropFilter: 'blur(4px)',
  },

  modal_visible: {
    maxWidth: '500px',
    width: '100%',
    padding: '25px 25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    borderRadius: '20px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },

  modal_text: {
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '0px',
  },

  modal_controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '15px',
  },
  close_button: {
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    margin: '10px 0',
  },

  button: {
    marginTop: '10px',
    marginLeft: '10px',
  },

  btnCont: {
    marginTop: '10px',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  formLabelStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'grey',
    marginTop: '10px',
    zIndex: 60002, // Raise the zIndex for the FormLabel
  },
  selectContainer: {
    position: 'relative',
    zIndex: 60003, // Raise the zIndex for the container
  },
  customSelect: {
    position: 'relative',
    width: '100%',
    zIndex: 60004, // Raise the zIndex for the custom select
  },
  customSelectInput: {
    width: '100%',
    padding: '20px 20px 5px 0px',
    border: 'none',
    borderBottom: '2px solid #ccc',
    outline: 'none',
    cursor: 'pointer',
  },
}));

const Modal = ({
  visible,
  text,
  handleClose,
  onChange,
  selectValues = [],
  handleSubmit,
  handleEditSubmit,
  isEdit,
  valuesObject,
  productUnit,
  setProductUnit,
  category = [],
  setCategory,
  selectedCategory = [],
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'; // Disable background scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable background scrolling
    }
  }, [visible]);

  return (
    <>
      {visible && (
        <div className={classes.gloabalModal}>
          <div className={classes.modal_visible}>
            <div className={classes.close_button}>
              <Iconify onClick={handleClose} icon={'eva:close-circle-fill'} />
            </div>
            <p className={classes.modal_text}>{text}</p>

            <div className={classes.modal_controls}>
              <FormLabel className={classes.formLabelStyle} component="legend">
                Category
              </FormLabel>
              <div className={classes.customSelect}>
                <select
                  name="category"
                  value={selectedCategory}
                  onChange={setCategory}
                  className={classes.customSelectInput}
                >
                  <option selected disabled />
                  {category?.map((value) => (
                    <option key={value._id} value={value?._id}>
                      {value?.name}
                    </option>
                  ))}
                </select>
              </div>
{selectedCategory &&
              <FormLabel className={classes.formLabelStyle} component="legend">
                Product
              </FormLabel>
}
{selectedCategory &&

              <div className={classes.customSelect}>
                <select
                  name="productId"
                  value={valuesObject.product?._id}
                  onChange={onChange}
                  className={classes.customSelectInput}
                >
                  <option selected disabled />
                  {selectValues?.map((value) => (
                    <option key={value._id} value={value?._id}>
                      {value?.name}
                    </option>
                  ))}
                </select>
              </div>}

{selectedCategory && selectValues.length > 0 &&

              <FormLabel className={classes.formLabelStyle} component="legend">
                Quantity {productUnit ? `in ${productUnit}` : ''}
              </FormLabel>
}

{selectedCategory && selectValues.length > 0 &&

              <Input
                value={valuesObject.quantity}
                onChange={onChange}
                name="quantity"
                type="number"
                placeholder=""
                className={classes.input}
              />
}
{selectedCategory &&

<FormLabel className={classes.formLabelStyle} component="legend">
  Out Of Scope
</FormLabel>
}
{selectedCategory &&

<Input
  value={valuesObject.oos}
  onChange={onChange}
  name="oos"
  type="text"
  placeholder=""
  className={classes.input}
/>
}
              {/* {!isEdit && (
                <FormLabel className={classes.formLabelStyle} component="legend">
                  User's Password
                </FormLabel>
              )}
              {!isEdit && ( <Input
                value={valuesObject.password}
                onChange={onChange}
                name="password"
                type="password"
                placeholder=""
                className={classes.input}
              />
              )} */}
{selectedCategory &&

              <div className={classes.btnCont}>
                <Button
                  variant="contained"
                  onClick={isEdit ? handleEditSubmit : handleSubmit}
                  color="primary"
                  className={`${classes.button} bg-[#605BFF] hover:bg-[#5651d7]`}
                >
                  Save
                </Button>
              </div>
}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
