import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Container, Stack, Typography, Button, Card, FormLabel, Input, TextField } from '@mui/material';
import {toast} from 'react-toastify'
import DatePicker from 'react-date-picker';
import Swal from 'sweetalert2'; // Import SweetAlert
import api from '../constants/api';
import PageLoader from '../components/loader';
import { setUser } from '../redux/slices/user/user';
import { AvailableLocations } from '../utils/helperfunction';

import Iconify from '../components/iconify';

const useStyles = makeStyles(() => ({
  modal_controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '15px',
  },

  formLabelStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'grey',
    marginTop: '10px',
  },
  input: {
    width: '100%',
    margin: '10px 0',
    // border: '1px solid',
    // borderRadius: '10px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    backgroundColor: '#FAFAFB',
    padding: '5px 10px',
    // background-color: '#FAFAF',
    // padding: '5px 10px'
  },
}));

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.userReducer?.user);
  const navigate = useNavigate();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [userDetails, setuserDetails] = useState({
    name: '',
    email: '',
    phoneNo: '',
    address: '',
    location: '',
  });

  const handleChange = (evt) => {
    const value = evt.target.value;
    setuserDetails({
      ...userDetails,
      [evt.target.name]: value,
    });
  };

  useEffect(() => {
    const { name, email, phoneNo, address, location } = state?.updatedUser;
    setuserDetails({
      ...userDetails,
      name,
      email,
      phoneNo,
      address,
      location,
    });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try{
      setLoading(true)
      const params = {
        _id: state?.updatedUser?._id,
        ...userDetails
      }
      delete params.email;
      const response = await api.updateUser(params);
      const updatedUser = response?.data;
      const newObj = {token: state?.token, updatedUser}
      dispatch(setUser(newObj))
      toast.success("Your account updated successfully");
      setLoading(false)
    }catch(error){
      setLoading(false)
      toast.error(error.message || error.toString());
    }
  }

  const onNewPassword = async () => {
    try{
      setLoading(true)
      const params = {
        _id: state?.updatedUser?._id
      }
      const response = await api.requestNewPassword(params);
      dispatch(setUser(''));
      Swal.fire({
        icon: 'success',
        title: 'Password Generated',
        text: 'We have given you the new password on your email...',
      });
      setLoading(false)
    }catch(error){
      setLoading(false)
      toast.error(error.message || error.toString());
    }
  }

  return (
    <>
      {loading && <PageLoader />}
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Update Profile
        </Typography>

        {/* <Button className="bg-[#605BFF] hover:bg-[#5651d7]" onClick={() => navigate('/booking')} variant="contained">
          Back
        </Button> */}
      </Stack>

      <Card sx={{ padding: 5 }}>
      <form method="POST" onSubmit={onSubmit}>
        <div className={classes.modal_controls}>
          <div className="w-full grid max-[500px]:grid-cols-1  grid-cols-2 gap-x-5 sm:grid-cols-2 md:gap-x-10 md:gap-y-5">
            <div>
              <FormLabel className={classes.formLabelStyle} component="legend">
                Full Name
              </FormLabel>
              <Input
                required
                value={userDetails.name}
                onChange={handleChange}
                name="name"
                placeholder=""
                className={classes.input}
              />
            </div>
            <div>
              <FormLabel className={classes.formLabelStyle} component="legend">
                Email
              </FormLabel>
              <Input
                required
                disabled
                value={userDetails.email}
                onChange={handleChange}
                type="email"
                name="email"
                placeholder=""
                className={classes.input}
              />
            </div>

            <div>
              <FormLabel className={classes.formLabelStyle} component="legend">
                Phone Number
              </FormLabel>
              <Input
                required
                value={userDetails.phoneNo}
                onChange={handleChange}
                name="phoneNo"
                type="number"
                placeholder=""
                className={classes.input}
              />
            </div>

            {/* <div>
              <FormLabel className={classes.formLabelStyle} component="legend">
                Password
              </FormLabel>
              <Input
                required
                // value={name}
                // onChange={(e) => setname(e.target.value)}
                type="password"
                name="number"
                disabled
                value={'password'}
                placeholder=""
                className={classes.input}
              />
            </div> */}

           <div>
           <FormLabel className={classes.formLabelStyle} component="legend">
                  Location
                </FormLabel>
                <select
                  value={userDetails.location}
                  onChange={handleChange}
                  required
                  name="location"
                  className="my-[10px] cursor-pointer rounded-t-[10px] bg-[#FAFAFB] border-b-2 outline-none border-b-gray-300 text-gray-900 text-smblock w-full p-2.5 "
                >
                  <option value="">select</option>
                  {AvailableLocations.map((item, index) => (
                    <option key={index} value={item.value}>{item.label}</option>
                  ))}
                </select>
           </div>

            <div className="col-span-full">
              <FormLabel className={classes.formLabelStyle} component="legend">
                Address
              </FormLabel>
              <TextField
                required
                id="standard-multiline-static"
                multiline
                rows={2}
                defaultValue={''}
                variant="standard"
                name="address"
                className={classes.input}
                value={userDetails.address}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end mt-10 gap-x-10 flex-row max-[500px]:flex-col">
          <Button
            className="bg-[#605BFF] hover:bg-[#5651d7] max-[500px]:w-full max-[500px]:py-3"
            onClick={onNewPassword}
            variant="contained"
          >
            Get my new Password of my email
          </Button>

          <Button type='submit' className="bg-[#605BFF] hover:bg-[#5651d7] max-[500px]:mt-4 max-[500px]:w-full  max-[500px]:py-3" onClick={() => {}} variant="contained">
            Save
          </Button>
        </div>
        </form>
      </Card>
    </Container>
    </>
  );
};

export default UpdateProfile;
