import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import api from '../constants/api';
import { AvailableLocations } from '../utils/helperfunction';
import TextField from '../components/TextField';

const RegisterPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    fullName: '',
    email: '',
    location: '',
    phoneNo: '',
    address: '',
    password: '',
  });

  const handleChange = (evt) => {
    const value = evt.target.value;
    setUserDetails({
      ...userDetails,
      [evt.target.name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSignUp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const params = {
        name: userDetails.fullName,
        email: userDetails.email,
        role: '652df12da46e2b67903f6e3c',
        location: userDetails.location,
        phoneNo: userDetails.phoneNo,
        address: userDetails.address
      };
      const response = await api.registerUser(params);
      Swal.fire({
        icon: 'success',
        title: response?.message,
        text: 'Please Login now, password has been sent to your email address.',
      });
      navigate('/auth/login');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Action Failed',
        text: error?.message || error?.toString(),
      });
    }
  };

  return (
    <div className="w-[90%] md:w-[80%]">
      <h1 className="text-[#605BFF] font-black text-3xl text-center">Supereme Facilities</h1>
      <h3 className="font-bold text-2xl text-center">Registration</h3>

      <form onSubmit={onSignUp}>
        <TextField
          type="text"
          label={'Full Name'}
          name="fullName"
          value={userDetails.fullName}
          onChange={handleChange}
        />

        <TextField
          type="email"
          label={'Email Address'}
          name="email"
          value={userDetails.email}
          onChange={handleChange}
        />

        <TextField
            type="number"
            label={"Phone Number"}
            name="phoneNo"
            value={userDetails.phoneNo}
            onChange={handleChange}
        />

        <TextField
            type="text"
            label={"Address"}
            name="address"
            value={userDetails.address}
            onChange={handleChange}
        />

        <div className="my-5">
          <label htmlFor="loc" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Location
            <select
              value={userDetails.location}
              onChange={handleChange}
              required
              name="location"
              className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">select</option>
              {AvailableLocations.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </label>
        </div>

        {/* <div className='my-5'>
        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Password
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={userDetails.password}
              onChange={handleChange}
              className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
            <button
              type="button"
              className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
        </label>
      </div> */}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          className="w-full focus:outline-none text-white bg-[#605BFF] hover:bg-[#5651d7] font-medium rounded-lg text-sm px-5 py-3 my-5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
          variant="contained"
          loading={loading} // Use the loading state to manage the loading state of the button
        >
          Create Account
        </LoadingButton>
        {/* <button type="submit" className="w-full focus:outline-none text-white bg-[#605BFF] hover:bg-[#5651d7] font-medium rounded-lg text-sm px-5 py-3 my-5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Create Account</button> */}
        <p className="text-base text-center">
          Not have an account?{' '}
          <Link to={'/auth/login'} className="text-[#605BFF]">
            Login
          </Link>{' '}
        </p>
      </form>
    </div>
  );
};

export default RegisterPage;
