import { Outlet } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from '../../components/logo';
import Illustration from '../../assets/Illustration.png';

// ----------------------------------------------------------------------

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  return (
    <>
      {/* <StyledHeader>
        <Logo />
        </StyledHeader> */}
      
      <div className='w-full h-screen bg-slate-400 flex flex-col md:flex-row'>
        <div className='grow order-2 md:order-2 w-auto sm:basis-[45%] lg:basis-[30%] bg-white  flex justify-center items-center'>
          <Outlet />
        </div>
        <div className='order-1 md:order-2 w-auto sm:basis-[55%] lg:basis-[70%] bg-[#F7F7F8] flex justify-center items-center' >
          <img src={Illustration} alt='my Illustration' className='h-auto w-full p-4 max-w-[500px] lg:w-[600px]' />
        </div>
      </div>
        
      {/* <Outlet /> */}
    </>
  );
}
