import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

import Swal from 'sweetalert2'; // Import SweetAlert
import axios from 'axios';
import api from '../constants/api';
import { setUser } from '../redux/slices/user/user';
import TextField from '../components/TextField';

const VerificationPage = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isOtpVerified, setisOtpVerified] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false); // State to manage loading
    const [otpCode, setotpCode] = useState("");
    const [npassword, setnpassword] = useState("");
    const [cpassword, setcpassword] = useState("");

    useEffect(() => {
      if(!location?.state){
        navigate("/auth/login")
      }
    }, [])


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onOTPVerification = async (e) => {
    e.preventDefault();
    try{
      setLoading(true);
      if(otpCode.length !== 6){
        Swal.fire({
          icon: 'error',
          title: 'Action Failed',
          text: "OTP code should be 6 digit...",
        });
        setLoading(false);
        return;
      }
      const params = {
        _id: location?.state?.data?.updatedUser?._id,
        otp: otpCode,
      };
      axios.defaults.headers.common = {
        'Authorization': `Bearer ${location?.state?.data?.token}`
      };

      const response = await api.verifyOTP(params);
      setLoading(false);
      setisOtpVerified(true);
    }catch(error){
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Action Failed',
        text: error?.message || error?.toString(),
      });
    }
  };

  const onUpdatePass = async () => {
    if(npassword.trim() === "" || cpassword.trim() === ""){
      Swal.fire({
        icon: 'error',
        title: 'Empty Field',
        text: 'Field should not be null...',
      });
      return;
    }
    if(npassword.trim() !== cpassword.trim()){
      Swal.fire({
        icon: 'error',
        title: 'Password not match',
        text: 'Please write the same password in both fields.',
      });
      return;
    }

    try{
      setLoading(true);
      // alert("Hello");
      const params = {
        _id: location?.state?.data?.updatedUser?._id,
        password: cpassword,
        isFirstLogin: false
      };
      axios.defaults.headers.common = {
        'Authorization': `Bearer ${location?.state?.data?.token}`
      };
      const response = await api.updateUser(params);
      dispatch(setUser(location?.state?.data))
      toast.success('User Updated successfully');
      setLoading(false);
    }catch(error){
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Action Failed',
        text: error?.message || error?.toString(),
      });
    }
  }

  return (
    <div className="w-[90%] md:w-[80%]">
      <h1 className="text-[#605BFF] font-black text-3xl text-center">Supereme Facilities</h1>
      <h3 className="font-bold text-2xl text-center">Verification</h3>

      {/* <div className='my-5'>
        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Email Address
          <input
            type="text"
            id="email"
            name="email"
            className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </label>
      </div> */}
      <form onSubmit={onOTPVerification}>
        <TextField
          type="email"
          label={'Email Address'}
          name="email"
          value={location?.state?.userDetails?.email}
          disabled
        />

        <div className="my-5">
          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-dark">
            OTP
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="otpCode"
                name="otpCode"
                disabled={isOtpVerified}
                value={otpCode}
                onChange={e => setotpCode(e.target.value)}
                className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
              <button
                type="button"
                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>
          </label>
        </div>

      {isOtpVerified ? 
        <>
        <TextField
          type="password"
          label={'New Password'}
          name="npassword"
          value={npassword}
          onChange={e => setnpassword(e.target.value)}
        />

        <TextField
          type="password"
          label={'Confirm New Password'}
          name="cpassword"
          value={cpassword}
          onChange={e => setcpassword(e.target.value)}
        />

      <LoadingButton
        fullWidth
        size="large"
        // type="submit"
        onClick={() => onUpdatePass()}
        className='w-full focus:outline-none text-white bg-[#605BFF] hover:bg-[#5651d7] font-medium rounded-lg text-sm px-5 py-3 my-5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900'
        variant="contained"
        loading={loading} // Use the loading state to manage the loading state of the button
      >
        Update Password
      </LoadingButton> 
      </> :
        <LoadingButton
        fullWidth
        size="large"
        type="submit"
        className='w-full focus:outline-none text-white bg-[#605BFF] hover:bg-[#5651d7] font-medium rounded-lg text-sm px-5 py-3 my-5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900'
        variant="contained"
        loading={loading} // Use the loading state to manage the loading state of the button
      >
        Verify OTP
      </LoadingButton>}

        {/* <button
          type="submit"
          className="w-full focus:outline-none text-white bg-[#605BFF] hover:bg-[#5651d7] font-medium rounded-lg text-sm px-5 py-3 my-5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
        >
          Verify OTP
        </button> */}
        <p className="text-base text-center">
          Not your email? <Link to={'/auth/login'} className='text-[#605BFF]'>Click here</Link>
        </p>
      </form>
    </div>
  )
}

export default VerificationPage