import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import { FormLabel, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2'; // Import SweetAlert
import { Helmet, HelmetProvider } from 'react-helmet-async';
import DatePicker from 'react-date-picker';
import api from '../../constants/api';
import { AvailableLocations, formatDateToISOString, formatTimeSlot } from '../../utils/helperfunction';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './styles.css';
import Iconify from '../iconify';

const useStyles = makeStyles(() => ({
  gloabalModal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.2)',
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'hidden',
    backdropFilter: 'blur(4px)',
  },

  modal_visible: {
    maxWidth: '500px',
    width: '100%',
    padding: '25px 25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    borderRadius: '20px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },

  modal_text: {
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '0px',
  },

  modal_controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '15px',
  },
  close_button: {
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    margin: '10px 0',
  },

  button: {
    marginTop: '10px',
    marginLeft: '10px',
  },

  btnCont: {
    marginTop: '10px',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  formLabelStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'grey',
    marginTop: '10px',
    // zIndex: 60002, // Raise the zIndex for the FormLabel
  },
  selectContainer: {
    position: 'relative',
    zIndex: 60003, // Raise the zIndex for the container
  },
  customSelect: {
    position: 'relative',
    width: '100%',
    zIndex: 60004, // Raise the zIndex for the custom select
  },
  customSelectInput: {
    width: '100%',
    padding: '20px 20px 5px 0px',
    border: 'none',
    borderBottom: '2px solid #ccc',
    outline: 'none',
    cursor: 'pointer',
  },
}));

const CustomizeModal = ({
  data,
  loadData,
  setisLoading,
  modalMode,
  visible,
  handleClose,
}) => {
  const user = useSelector((state) => state?.userReducer?.user?.updatedUser);
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState('');
  // const [name, setname] = useState('');
  const [location, setlocation] = useState('');
  const [date, setdate] = useState('');
  const [availableSlots, setavailableSlots] = useState([]);
  const [slotEmptyMsg, setslotEmptyMsg] = useState('');

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'; // Disable background scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable background scrolling
    }
  }, [visible]);

  useEffect(() => {
    if (location?.trim() !== '' && date !== '') {
      fetchSlots();
    }
  }, [date, location]);

  const fetchSlots = async () => {
    try {
      setisLoading(true)
      const params = {
        dateSlot: date,
        location,
      };
      const response = await api.fetchAvailableSlots(params);
      setavailableSlots(response?.data);
      setisLoading(false)
    } catch (error) {
      setisLoading(false)
      setavailableSlots([]);
      if (error?.message === 'errorStrings.NO_ENGINEER_AVAILABLE') {
        setslotEmptyMsg('No Slots available on this date.');
        return;
      }
      Swal.fire({
        icon: 'error',
        title: 'Action Failed',
        text: error?.message,
      });
    }
  };

  const slots = [
    {
      time: '10AM-11AM',
      engName: 'abc abc',
    },
    {
      time: '11AM-12PM',
      engName: 'abc abc',
    },
    {
      time: '12PM-1PM',
      engName: 'abc abc',
    },
    {
      time: '1PM-2PM',
      engName: 'abc abc',
    },
  ];

  const handleItemClick = (index) => {
    setSelectedItem(index === selectedItem ? null : index);
  };

  const handleItemKeyPress = (index, event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleItemClick(index);
    }
  };

  const getModalText = (modalMode) => {
    if (modalMode === 'inspection') return 'Create Inspection';
    if (modalMode === 'pickup') return 'Select Device Pickup Slots';
    if (modalMode === 'installation') return 'Select Installation Time Slot';
    return 'NA';
  };

  const onSubmit = async () => {
    if (location.trim() === '' || date === '' || selectedItem === '') {
      Swal.fire({
        icon: 'error',
        title: 'Empty Field',
        text: 'Field should not be empty.',
      });
      return;
    }
    try{
      setisLoading(true)
      const params = {
        timeSlot: selectedItem,
        dateSlot: formatDateToISOString(date),
        location,
      };
      if (modalMode === 'inspection'){
        params.user = user?._id;
        params.bookingStatus = "654c17704a6ba736343d8db0";
        const response = await api.createBooking(params);
        toast.success("Slot Booked Successfully...");
      }else if(modalMode === 'pickup'){
        params._id = data?._id;
        params.bookingStatus = "654c17844a6ba736343d8db5";
        const response = await api.updateBooking(params);
        toast.success("Pickup Scheduled Successfully...");
      }else if(modalMode === 'installation'){
        params._id = data?._id;
        params.bookingStatus = "654c17534a6ba736343d8dab";
        const response = await api.updateBooking(params);
        toast.success("Installation Scheduled Successfully...");
      }
      // const response = await api.createBooking(params);
      // toast.success("Slot Booked Successfully...");
      loadData();
      handleClose();
      setisLoading(false)
    }catch(error){
      setisLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Action Failed',
        text: error?.message,
      });
    }
    
  };

  return (
    <>
      {/* {loading && <PageLoader />} */}
      {visible && (
        <div className={classes.gloabalModal}>
          <div className={classes.modal_visible}>
            <div className={classes.close_button}>
              <Iconify onClick={handleClose} icon={'eva:close-circle-fill'} />
            </div>
            <p className={classes.modal_text}>{getModalText(modalMode)}</p>

            <div className={classes.modal_controls}>
              {/* <FormLabel className={classes.formLabelStyle} component="legend">
                Name
              </FormLabel>
              <Input
                value={name}
                onChange={(e) => setname(e.target.value)}
                name="name"
                placeholder=""
                className={classes.input}
              /> */}

              <div className="my-[10px] w-full">
                <FormLabel className={classes.formLabelStyle} component="legend">
                  Location
                </FormLabel>
                <select
                  value={location}
                  onChange={(e) => setlocation(e.target.value)}
                  required
                  name="location"
                  className="mt-1 pl-0 bg-none border-b-2 outline-none border-b-gray-300 text-gray-900 text-smblock w-full p-2.5 "
                >
                  <option value="">select</option>
                  {AvailableLocations.map((item, index) => (
                    <option key={index} value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>

              <FormLabel className={classes.formLabelStyle} component="legend">
                Date
              </FormLabel>
              <DatePicker
                className={
                  'w-full my-[10px] border-b-[2px] border-b-[#ccc] border-solid hover:border-b-[#212B36] cursor-pointer'
                }
                dayPlaceholder="DD"
                yearPlaceholder="YY"
                monthPlaceholder="MM"
                calendarClassName={'calenderClass'}
                calendarIcon=""
                clearIcon=""
                minDate={new Date()}
                onChange={setdate}
                value={date}
              />

              <FormLabel className={classes.formLabelStyle} component="legend">
                Slot
              </FormLabel>

              <div className="flex flex-wrap w-full gap-3 mt-3">
                {availableSlots?.length > 0 ? (
                  availableSlots.map((item, index) => (
                    <div
                      key={index}
                      className={`${
                        selectedItem === index + 1 ? 'bg-[#8e8eab86]' : 'bg-[#ECEBFF]'
                      } p-3 rounded-lg basis-1/2`}
                      onClick={() => setSelectedItem(item?.slot)}
                      onKeyPress={(e) => handleItemKeyPress(index, e)}
                      role="button"
                      tabIndex={0}
                      style={{ boxSizing: 'border-box', flex: '0 0 calc(50% - 1rem)' }}
                    >
                      <h1 className="text-lg py-0 font-semibold leading-none">{formatTimeSlot(item)}</h1>
                      {/* <p className="text-sm text-[#707070]">Engr. {item.engName}</p> */}
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-400">
                    {slotEmptyMsg || 'Please select location and date to get available slots.'}
                  </p>
                )}
              </div>

              <div className={classes.btnCont}>
                <Button
                  variant="contained"
                  // onClick={isEdit ? handleEditSubmit : handleSubmit}
                  onClick={onSubmit}
                  color="primary"
                  className={`${classes.button} bg-[#605BFF] hover:bg-[#5651d7]`}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizeModal;
