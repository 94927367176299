const TimeSlots = [
    {
        time: "09:00 - 10:00",
        slot: 1
    },
    {
        time: "10:00 - 11:00",
        slot: 2
    },
    {
        time: "11:00 - 12:00",
        slot: 3
    },
    {
        time: "12:00 - 13:00",
        slot: 4
    },
    {
        time: "13:00 - 14:00",
        slot: 5
    },
    {
        time: "14:00 - 15:00",
        slot: 6
    },
    {
        time: "15:00 - 16:00",
        slot: 7
    },
    {
        time: "16:00 - 17:00",
        slot: 8
    }
]


const AvailableLocations = [
    {label: "KHI", value: "KHI"},
    {label: "LHR", value: "LHR"},
    {label: "ISL", value: "ISL"}
]

function formatDateToISOString(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
    return formattedDate;
}

function formatTimeSlot(slot) {
    const [startTime, endTime] = slot.time.split(' - ');
  
    // Convert start and end times to Date objects
    const startDate = new Date(`2023-11-25T${startTime}:00.000Z`);
    const endDate = new Date(`2023-11-25T${endTime}:00.000Z`);
  
    // Subtract 30 minutes from the end time
    endDate.setMinutes(endDate.getMinutes() - 30);
  
    // Format the times back into the desired string format
    const formattedStartTime = startDate.toISOString().slice(11, 16);
    const formattedEndTime = endDate.toISOString().slice(11, 16);
  
    return `${formattedStartTime} - ${formattedEndTime}`;
  }

export {
    TimeSlots,
    AvailableLocations,
    formatDateToISOString,
    formatTimeSlot
}