import { Helmet } from 'react-helmet-async';
import { add, filter, set } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  FormLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import api from '../constants/api';
import PageLoader from '../components/loader';
import ModalCreate from '../components/newSRMod';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'cat', label: 'Category', alignRight: false },
  { id: 'unit', label: 'Unit', alignRight: false },
  { id: 'iu', label: 'Item/Unit', alignRight: false },
  { id: 'totalqtyor', label: 'Ordered Quantity ', alignRight: false },
  { id: 'totalqty', label: 'Total Quantity', alignRight: false },
  { id: 'oos', label: 'Out Of Scope', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SpreadsheetsRecords() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [addData, setAddData] = useState({});
  const [isEdit, setIsEditData] = useState(false);
  const [editData, setEditData] = useState({});
  const [openNewUser, setOpenNewUser] = useState(false);
  const [roles, setRoles] = useState(false);
  const [productUnit, setProductUnit] = useState(false);
  const [spId, setSpid] = useState(null);
  const [category, setCateogry] = useState([]);
  const [selectedCategory, setSelectedCateogry] = useState(null);
  const [selectedFilterCategory, setSelectedFilterCateogry] = useState(null);
  const navigate = useNavigate();
  const [USERLIST, setUserList] = useState([]);

  useEffect(() => {
    //  get url query string

    // if (!spreadsheetId) {
    //   navigate(`/spreadsheet`);
    // }
    fetchUsers();
    fetchRoles();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [page]);

  const fetchRoles = async () => {
    try {
      setLoading(true);

      const response = await api.fetchProducts(
        selectedCategory ? { cateogry: selectedCategory } : {},
      );
      // const responseCategory = await api.fetchCategory();

      setRoles(response.data);
      // setCateogry(responseCategory.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const fetchPRD = async () => {
    try {
      setLoading(true);

      const response = await api.fetchCategory();

      setCateogry(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [spId]);

  const fetchUsers = async (selCat) => {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const spreadsheetId = urlParams.get('spreadsheetId');

      console.warn(selCat);
      setLoading(true);

      const response = await api.fetchSpreadsheetRecords({
        page: 1 + page,
        spreadsheetId,
        category: selectedFilterCategory,
      });

      setUserList(response.data);
      setCount(response.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  function convertToSlug(inputString) {
    return inputString.toLowerCase().replace(/\s+/g, '-');
  }

  const saveUser = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const spreadsheetId = urlParams.get('spreadsheetId');

    console.warn(spId);
    try {
      setLoading(true);

      if (!addData?.productId || !addData?.quantity || !selectedCategory) {
        throw new Error('Please fill all the fields');
      }
      addData.spreadsheetId = spreadsheetId;
      addData.categoryId = selectedCategory._id;
      const response = await api.createSpreadsheetRecords(addData);

      if (page !== 0) setPage(0);
      else {
        fetchUsers();
      }

      toast.success('Added successfully');

      setOpenNewUser(false);
      setAddData({});

      setSelectedCateogry(null);
      setProductUnit(null);
      setEditData({});
      setOpenNewUser(false);
      setIsEditData(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // setOpenNewUser(false);
      // setAddData({});
      toast.error(error.message);
    }
  };

  const editUser = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const spreadsheetId = urlParams.get('spreadsheetId');

    console.warn(spId);
    try {
      setLoading(true);

      const changedFields = {};
      Object.keys(editData).forEach((key) => {
        if (key in editData && editData[key] !== selected[key]) {
          if (!editData[key]) {
            throw new Error('Please fill all the fields');
          }
          changedFields[key] = editData[key];
        }
      });

      if (Object.keys(changedFields).length === 0) throw new Error('No changes made');

      changedFields._id = selected._id;
      const response = await api.updateSpreadsheetRecords(changedFields);

      if (page !== 0) setPage(0);
      else {
        fetchUsers();
      }

      toast.success('Editted successfully');

      setOpenNewUser(false);
      setSelectedCateogry(null);
      setProductUnit(null);
      setEditData({});
      setOpenNewUser(false);
      setIsEditData(false);
      setIsEditData(false);
      setSelectedCateogry(null);
      setEditData({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);

      const response = await api.updateSpreadsheetRecords({ _id: selected?._id, isActive: false });

      if (page !== 0) setPage(0);
      else {
        fetchUsers();
      }

      setLoading(false);
      handleCloseMenu();

      toast.success('User deleted successfully');
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const resetPassword = async () => {
    try {
      setLoading(true);

      const response = await api.requestNewPassword({ _id: selected?._id });

      setLoading(false);
      handleCloseMenu();

      toast.success('Password reset link sent to user email');
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const openEditModal = () => {
    setOpen(false);
    setIsEditData(true);
    console.log(selected)
    setSelectedCateogry(selected?.product?.cateogry?._id);
    setEditData(selected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onFocusLost = () => {
    if (page !== 0) setPage(0);
    else {
      fetchUsers();
    }
  };

  const handleOpenMenu = (e, user) => {
    setOpen(e.currentTarget);
    setSelected(user);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleOnChange = (e) => {
    if (isEdit) {
      setEditData({ ...editData, [e.target.name]: e.target.value });
      return;
    }

    if (e.target.name === 'productId') {
      const product = roles.find((item) => item._id === e.target.value);

      setProductUnit(product?.unit);
    }

    setAddData({ ...addData, [e.target.name]: e.target.value });
  };

  const navigateToURLWithQuery = () => {
    window.location.href = `/spreadsheets/records?spreadsheetId${convertToSlug(selected._id)}`;
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
  const filteredUsers = USERLIST;
  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy));
  const isNotFound = !filteredUsers.length;

  const emailUser = async () => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const spreadsheetId = urlParams.get('spreadsheetId');

      const apiRes = await api.mailSpreadsheetRecords({
        spreadsheetId,
        category: selectedFilterCategory,
      });
      setLoading(false);
      toast.success('Mailed');
    } catch (error) {
      setLoading(false);
      toast.error('Error Occured');
    }
  };

  const categorySubmit = async (e) => {
    setSelectedCateogry(e.target.value);
  };

  const onCateChange = async (e) => {
    setSelectedFilterCateogry(e.target.value);
    console.log(e.target.value);
  }

  useEffect(() => {
    if (selectedFilterCategory) {
      fetchUsers(selectedFilterCategory);
    }
  }
  , [selectedFilterCategory]);

  useEffect(() => {
    fetchRoles();
  }, [selectedCategory]);
  useEffect(() => {
    fetchPRD();
  }, []);

  return (
    <>
      {loading && <PageLoader />}

      <ModalCreate
        isEdit={isEdit}
        valuesObject={editData}
        selectValues={roles}
        handleSubmit={saveUser}
        handleEditSubmit={editUser}
        onChange={handleOnChange}
        category={category}
        setCategory={categorySubmit}
        selectedCategory={selectedCategory}
        productUnit={productUnit}
        handleClose={() => {
          setSelectedCateogry(null);
          setProductUnit(null);
          setEditData({});
          setOpenNewUser(false);
          setIsEditData(false);
        }}
        visible={openNewUser || isEdit}
        text={isEdit ? 'Edit Details' : 'Add Details'}
      />

      <Helmet>
        <title> Inventory Records  </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Inventory Records
          </Typography>
          <div>
                <select
                  name="role"
                  placeholder='Select Category'
                  style={{
                    margin: '0 10px 0 0',
                    padding: '5px 100px 5px 0px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                  value={selectedFilterCategory ? selectedFilterCategory._id: "all"}
                  onChange={onCateChange}
                  // className={classes.customSelectInput}
                >
                  <option selected value={'all'} > All Category </option> 
                  {category?.map((value) => (
                    <option key={value._id} value={value?._id}>
                      {value?.name}
                    </option>
                  ))}
                </select>
          {USERLIST.length > 0 && 
            <Button
              className="bg-[#605BFF] hover:bg-[#5651d7] mr-2"
              onClick={() => emailUser()}
              variant="contained"
              startIcon={<Iconify icon="eva:email-fill" />}
            >
              Email Now
            </Button>
          }
            <Button
              className="bg-[#605BFF] hover:bg-[#5651d7]"
              onClick={() => setOpenNewUser(true)}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add New
            </Button>
          </div>
        </Stack>

        <Card>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onLostFocus={onFocusLost}
            title={'User Records'}
            searchTitle={'Search by email'}
          /> */}
          <h2 className="text-lg font-extrabold px-4 py-6">Spreadsheet Records</h2>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  // onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.map((row) => {
                    const { _id, product, quantity, oos } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="2">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {product?.name ? product?.name : 'No Name'}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="2">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {product?.cateogry?.name ? product?.cateogry?.name : 'No Category'}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="2">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {product?.unit ? product?.unit : 'No Unit'}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="2">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {product?.itemPerUnit ? product?.itemPerUnit : 'No Details '}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="2">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {quantity}
                            </Typography>
                          </Stack>
                        </TableCell>


                        <TableCell component="th" scope="row" padding="2">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {quantity * product?.itemPerUnit} Items
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="2">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {!oos ? '-' : oos}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          {/* <Typography variant="h6" paragraph>
                            Not found
                          </Typography> */}

                          <Typography variant="body2">No results found</Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={count}
            rowsPerPage={5}
            page={page}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={openEditModal}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={deleteUser} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
